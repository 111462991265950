<template>
  <a-modal
      :visible="visible"
      title="交易详情 (单位：元)"
      :mask-closable="false"
      :width="900"
      @ok="handleOk"
      centered
      @cancel="handleCancel"
      forceRender
      :destroyOnClose="true"
  >

    <div class="title">
      <div class="icon"><InfoCircleOutlined /></div>
      <div class="text">交易成功</div>
    </div>

    <div class="issTable  mt-20">
      <a-table :columns="columns" :data-source="data" :rowKey="(record) => record.id">
      </a-table>
    </div>

    <div class="issTable mt-10">
      <a-table :columns="columnsT" :data-source="dataT" :rowKey="(record) => record.id">
      </a-table>
    </div>

    <template v-slot:footer>
<!--      <a-button @click="handleCancel">取消</a-button>-->
      <a-button type="primary" @click="handleOk" :loading="loading"
      >导出详情</a-button
      >
    </template>
  </a-modal>

</template>
<script>
import { reactive, toRefs, watch} from 'vue';
import {
  InfoCircleOutlined,
} from '@ant-design/icons-vue';
import { Table, } from 'ant-design-vue';
import ordersApi from "@/api/orders";
import {downloadExcel} from "@/utils";
// import { useStore } from 'vuex';


export default {
  components: {
    InfoCircleOutlined,
    ATable: Table,

  },
  props: {
    visible: Boolean,
    init: Object,
  },
  setup(props, context) {
    // const store = useStore();
    const form = reactive({
      initVal: {},

    });

    const state = reactive({
      dataList: [],
      dataListT: [],
      loading: false,
      data: [
        {
          id: 1,
          packageTitle: '',
          userName: '',
          // mobile: null,
          createTime: '',
          successTime: '',
          packageName: '',

        },
      ],
      dataT: [
        {
          id: 2,
          orderFeel: '',
          payerTotal: null,
          goldNumber: '',
          paymentType: '',
          orderNo: '',
          transactionId: '',
          packageType: '',
        },
      ],

    });

    const handleCancel = () => {
      context.emit('update:visible', false);
      state.dataList = []
      // 表单重置
    };
    const columns = [
      {
        title: '订单名称',
        dataIndex: 'packageTitle',
        key: 'packageTitle',
        slots: {
          title: 'customTitle',
          customRender: 'name',
        },
      },
      {
        title: '用户名称',
        dataIndex: 'userName',
        key: 'userName',
      },
      // {
      //   title: '手机号',
      //   dataIndex: 'mobile',
      //   key: 'mobile',
      // },
      {
        title: '订单创建时间',
        key: 'createTime',
        dataIndex: 'createTime',
      },
      {
        title: '付款时间',
        dataIndex: 'successTime',
        key: 'successTime',
      },
      {
        title: '支付项目',
        dataIndex: 'packageName',
        key: 'packageName',
      },

    ];
    const columnsT = [
      {
        title: '订单金额',
        dataIndex: 'orderFeel',
        key: 'orderFeel',

      },
      {
        title: '实收',
        dataIndex: 'payerTotal',
        key: 'payerTotal',
      },

      {
        title: '金币数量',
        dataIndex: 'goldNumber',
        key: 'goldNumber',
      },
      {
        title: '支付方式',
        key: 'paymentType',
        width: 100,
        dataIndex: 'paymentType',
      },
      {
        title: '订单号',
        dataIndex: 'orderNo',
        key: 'orderNo',
        width: 180,
        // ellipsis: true,
      },
      {
        title: '交易号',
        dataIndex: 'transactionId',
        key: 'transactionId',
        width: 180,
        // ellipsis: true,
      },
      {
        title: '支付类型',
        dataIndex: 'packageType',
        key: 'packageType',

      },


    ];

    watch(
        () => props.init,
        value => {
          // console.log('value56', value);
          // 列表一
          form.initVal = value
          state.data[0].packageTitle = value.packageTitle
          state.data[0].userName = value.userName
          state.data[0].createTime = value.createTime
          state.data[0].successTime = value.successTime
          state.data[0].packageName = value.packageName


          // 列表二
          let payment = ''
          if (value.paymentType === 'zfb') {
            payment = '支付宝'
          } else if (value.paymentType === 'wx') {
            payment = '微信'
          } else if (value.paymentType === 'ApplePay') {
            payment = '苹果支付'
          } else {
            payment = ''
          }

          let packageType = ''
          if (value.packageType === 1) {
            packageType = '金币充值'
          } else if (value.packageType === 0) {
            packageType = '订阅套餐'
          } else {
            packageType = ''
          }
          // 订单金额/100
          let isOrderFeel = null
          if (value.orderFeel) {
            isOrderFeel = value.orderFeel>0 ? value.orderFeel / 100 : 0
          }
          // 实收/100
          let isPayerTotal = null
          if (value.payerTotal) {
            isPayerTotal = value.payerTotal>0 ? value.payerTotal / 100 : 0
          }

          state.dataT[0].id = value.id
          state.dataT[0].orderFeel = isOrderFeel
          state.dataT[0].payerTotal = isPayerTotal
          state.dataT[0].goldNumber = value.goldNumber
          state.dataT[0].paymentType = payment
          state.dataT[0].orderNo = value.orderNo
          state.dataT[0].transactionId = value.transactionId
          state.dataT[0].packageType = packageType


        }
    );


    return {
      ...toRefs(state),
      form,
      handleCancel,
      columns,
      columnsT,
      handleOpen() {
        state.openTiesGroup = true;
        // context.emit('fnOk', false);
      },
      handleOk: () => {
        // 导出单条用户数据
        ordersApi
            .export('contact:export', {id: form.initVal.id})
            .then(({ name, data }) => downloadExcel(name, data));
        context.emit('fnOk', false);

      },
    };
  },
};
</script>
<style lang="less" scoped>
.panel {
  margin-left: 10px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 13px;
  padding-bottom: 13px;
  border-bottom: 1px solid #eeeeee;
}
.title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 20px;
  background: #e5f5ff;
  border: 1px solid #eeeeee;
  .icon {
    margin-right: 10px;
    color: #0099ff;

  }
  .text {

  }

}
.objStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #eeeeee;
  margin-bottom: 20px;
  margin: 20px 0px;
  padding: 10px 5px;

  .objDataStyle {

    .objKey {
      //background: #eeeeee;
    }
    .objObj {
      margin-top: 20px;
    }
  }
}

.issTable /deep/ .ant-table-pagination.ant-pagination {
  display: none;
}
.issTable /deep/ .ant-table-tbody > tr > td {
  border-bottom: none;
}

</style>
